import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    Button, Layout, Row,
    Col, Card,Tabs,Slider,
    Form, Input, Space, Carousel, Spin,
    Notification, Message, Modal, Select, Table, Image,
    Empty, Switch, Popconfirm, Popover, DatePicker,Descriptions,Dropdown, List, Mentions,
    Anchor, Rate, Result, Checkbox, ConfigProvider, Drawer
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.variable.less';
import 'ant-design-vue/dist/antd.less'
import VueWechatTitle from 'vue-wechat-title'
const app = createApp(App)
app.directive('hover', {
    mounted(el) {
        el.onmouseenter = function () {
            el.style.color = "#009FE9"
            el.style.cursor = 'pointer'
        }
        el.onmouseleave = function () {
            el.style.color = "#000"
        }
    }
})
app.directive('bghover', {
    mounted(el) {
        el.onmouseenter = function () {
            el.style.backgroundColor = "#eee"
            el.style.cursor = 'pointer'
        }
        el.onmouseleave = function () {
            el.style.backgroundColor = "transparent"
        }
    }
})
app.use(Button)
    .use(Layout)
    .use(Row)
    .use(Col)
    .use(Card)
    .use(Card)
    .use(Form)
    .use(Input)
    .use(Tabs)
    .use(Slider)
    .use(Space)
    .use(Carousel)
    .use(Spin)
    .use(Modal)
    .use(Select)
    .use(Table)
    .use(Image)
    .use(Empty)
    .use(Switch)
    .use(Popconfirm)
    .use(Popover)
    .use(DatePicker)
    .use(Dropdown)
    .use(Descriptions)
    .use(List)
    .use(Mentions)
    .use(Anchor)
    .use(Rate)
    .use(Checkbox)
    .use(Result)
    .use(Notification).use(Message)
    .use(ConfigProvider)
    .use(Drawer)
    .use(store)
    .use(router)
    .use(VueWechatTitle)
    .mount('#app')

